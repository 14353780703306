@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  -webkit-font-smoothing: antialiased;
}

@font-face {
  font-family: 'Inter';
  src: url('./assets/fonts/inter_variable.woff2') format('woff2');
  font-weight: 100 1000;
  font-display: block;
}

@import url('https://fonts.googleapis.com/css?family=Roboto+Mono');

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 47.4% 11.2%;

    --border: 0 0% 90%;
    --input: 0 0% 90%;

    --hover: 0 0% 96%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 47.4% 11.2%;

    --primary: 0 0% 9%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 343 80 35%;
    --destructive-foreground: 210 40% 98%;

    --ring: 215 20.2% 65.1%;

    --radius: 0.6rem;
  }

  .dark {
    --background: 224 71% 4%;
    --foreground: 213 31% 91%;

    --muted: 223 47% 11%;
    --muted-foreground: 215.4 16.3% 56.9%;

    --accent: 216 34% 17%;
    --accent-foreground: 210 40% 98%;

    --popover: 224 71% 4%;
    --popover-foreground: 215 20.2% 65.1%;

    --border: 216 34% 17%;
    --input: 216 34% 17%;

    --card: 224 71% 4%;
    --card-foreground: 213 31% 91%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 1.2%;

    --secondary: 222.2 47.4% 11.2%;
    --secondary-foreground: 210 40% 98%;

    --destructive: 0 63% 31%;
    --destructive-foreground: 210 40% 98%;

    --ring: 216 34% 17%;

    --radius: 0.5rem;
  }
}

@layer base {
  * {
    @apply border-border font-inter;
  }
  body {
    @apply bg-transparent text-foreground;
    font-feature-settings:
      'rlig' 1,
      'calt' 1;
  }
}

::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

::-webkit-scrollbar-track {
  background: #fafafa;
  /* border: 1px solid hsl(var(--border)); */
}

pre::-webkit-scrollbar-track {
  background: transparent;
  border: none;
}

pre::-webkit-scrollbar-corner {
  background: transparent;
}

pre::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.15);
  border: 2px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border-radius: 9999px;
}

pre::-webkit-scrollbar-thumb:hover {
  background: rgba(255, 255, 255, 0.3);
  border: 2px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border-radius: 9999px;
}

::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.15);
  border: 2px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border-radius: 9999px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.3);
  border: 2px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border-radius: 9999px;
}

code * {
  @apply font-mono;
  /* Only for demo */
}

textarea:focus {
  outline: none !important;
  /* border-color: #1e293b !important; */
}

/* * { */
/*   border: 1px solid black; */
/* } */

h1 {
  @apply text-4xl font-bold;
}

h2 {
  @apply text-3xl font-bold;
}

h3 {
  @apply text-2xl font-bold;
}

h4 {
  @apply text-xl font-bold;
}

h5 {
  @apply text-lg font-bold;
}

h6 {
  @apply text-base font-bold;
}

a {
  @apply text-primary underline;
}

@layer utilities {
  .font-thin {
    font-variation-settings: 'wght' 100;
  }
  .font-extralight {
    font-variation-settings: 'wght' 200;
  }
  .font-light {
    font-variation-settings: 'wght' 300;
  }
  .font-normal {
    font-variation-settings: 'wght' 400;
  }
  .font-medium {
    font-variation-settings: 'wght' 500;
  }
  .font-semibold {
    font-variation-settings: 'wght' 600;
  }
  .font-bold {
    font-variation-settings: 'wght' 700;
  }
  .font-extrabold {
    font-variation-settings: 'wght' 800;
  }
  .font-black {
    font-variation-settings: 'wght' 900;
  }
}
